import React from "react";

// Root Include
const Root = React.lazy(() => import('./pages/Landing'));

const Signup = React.lazy(() =>
  import("./pages/Landing/Pages/Signup")
);

const Login = React.lazy(() =>
  import("./pages/Landing/Pages/Login")
);

const RecoveryPassword = React.lazy(() =>
  import("./pages/Landing/Pages/RecoveryPassword")
);

const Landing = React.lazy(() => import("./pages/Landing"));

const Project = React.lazy(() =>
  import("./pages/Landing/Pages/Project")
);

const Account = React.lazy(() =>
  import("./pages/Landing/Pages/Account")
);

const Modify = React.lazy(() =>
  import("./pages/Landing/Pages/Modify")
);

const ModifyPassword = React.lazy(() =>
  import("./pages/Landing/Pages/ModifyPassword")
);

const routes = [
  //routes without Layout
  {
    path: "/signup",
    component: Signup,
    isWithoutLayout: true,
  },

  {
    path: "/login",
    component: Login,
    isWithoutLayout: true,
  },

  {
    path: "/recovery-password",
    component: RecoveryPassword,
    isWithoutLayout: true,
  },

  //routes with Layout
  { path: "/index-onepage", component: Landing },
  { path: "/project", component: Project },
  { path: "/account", component: Account },
  { path: "/modify/:id", component: Modify, isWithoutLayout: true },
  { path: "/password/:id", component: ModifyPassword, isWithoutLayout: true },
  { path: "/", component: Root, isWithoutLayout: true, exact: true },
  { component: Landing, isWithoutLayout: false, exact: false },
];

export default routes;
